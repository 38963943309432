import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { exportReviewsToXlsx, loadData } from './ConsoleReviewPage.duck';
import {
  PaginationLinks,
  ConsoleWrapper,
  IconSpinner,
  NamedLink,
  Table,
  DatePicker,
  Button,
  Select,
  Checkbox,
} from '../../components';
import ReviewItem from './ReviewItem/ReviewItem';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';

import css from './ConsoleReviewPage.css';

const reviewCountry = [{ countryId: 'all', country: 'Console.all' }, ...config.countryNames];

const defaultRatingFilter = [
  { value: '1', checked: false },
  { value: '2', checked: false },
  { value: '3', checked: false },
  { value: '4', checked: false },
  { value: '5', checked: false },
];

const timePeriodText = [
  { text: 'Console.allTime', value: false },
  { text: 'Console.partTime', value: true },
];

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();
const firstDay = new Date(currentYear, currentMonth, 2).toISOString().split('T')[0];
const lastDay = new Date().toISOString().split('T')[0];

const ConsoleReviewPageComponent = props => {
  const {
    reviews,
    fetchInProgress,
    scrollingDisabled,
    fetchReviewListError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    name,
    tab,
    currentUser,
    userProviders,
  } = props;

  const {
    start = firstDay,
    end = lastDay,
    countryId = config.custom.countryId,
    providerId,
    periodTime = false,
    rating,
  } = parse(search);
  const ratingValue = rating?.length > 1 ? rating?.split(',') : rating?.toString();
  const dispatch = useDispatch();

  const ratingFilterCurrent = defaultRatingFilter.map(({ value }) => ({
    value,
    checked: ratingValue?.includes(value),
  }));

  const [ratingFilter, setRatingFilter] = useState(
    ratingValue ? ratingFilterCurrent : defaultRatingFilter
  );
  const [isExpandedReview, setIsExpandedReview] = useState(false);

  const tableConfig = [
    {
      label: intl.formatMessage({ id: 'Console.customer' }),
      render: review => (
        <p className={css.cell}>
          {review?.transaction?.attributes.firstName} {review?.transaction?.attributes.lastName}
        </p>
      ),
    },
    {
      label: intl.formatMessage({ id: 'Console.provider' }),
      render: review => review?.provider?.attributes.name,
    },
    {
      label: intl.formatMessage({ id: 'ConsoleTransactionPage.country' }),
      render: review => review?.provider?.attributes?.countryId.toUpperCase(),
    },
    {
      label: intl.formatMessage({ id: 'Console.rating' }),
      render: review => review.attributes.rating,
    },
    {
      label: intl.formatMessage({ id: 'Console.ratingText' }),
      render: review => (
        <ReviewItem text={review.attributes.text} intl={intl} isExpandedReview={isExpandedReview} />
      ),
    },
    {
      label: intl.formatMessage({ id: 'Console.date' }),
      render: review =>
        intl.formatDate(review.attributes.createdAt, {
          month: 'numeric',
          year: 'numeric',
          day: 'numeric',
        }),
    },
    {
      label: intl.formatMessage({ id: 'Console.transactionDetails' }),
      render: review =>
        review?.transaction?.id.uuid ? (
          <NamedLink
            className={css.link}
            name="SaleDetailsPage"
            params={{ id: review?.transaction?.id.uuid }}
          >
            <FormattedMessage id="Console.link" />
          </NamedLink>
        ) : null,
    },
  ];

  const keyFn = review => review?.id.uuid;

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = fetchReviewListError ? (
    <p className={css.error}>{fetchReviewListError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !reviews?.length && !fetchReviewListError ? (
      <p>
        <FormattedMessage id="Console.noReviewsFound" />
      </p>
    ) : null;

  const handleCountryChange = event => {
    const currentCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), countryId: currentCountryId, providerId: undefined, page: 1 })}`,
    });
  };

  const countries = currentUser?.attributes.isAdmin
    ? reviewCountry
    : reviewCountry.filter(country =>
        currentUser?.attributes.adminForCountries.includes(country.countryId)
      );

  const countryReviewSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={countryId || 'all'}
        onChange={handleCountryChange}
        label={intl.formatMessage({ id: "ConsoleTransactionPage.country"})}
      >
        <option hidden value="">
          {intl.formatMessage({ id: 'Console.select' })}
        </option>
        {countries.map(({ countryId, country }) => (
          <option value={countryId}>{intl.formatMessage({ id: country })}</option>
        ))}
      </Select>
    </div>
  );

  const handleProviderChange = event => {
    const providerId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), providerId, page: 1 })}`,
    });
  };

  const filterProviders = userProviders.filter(
    ({ attributes }) => attributes.countryId === countryId
  );

  const sortedProviders = filterProviders.sort((a, b) =>
    b.attributes?.name.toLowerCase() < a.attributes?.name.toLowerCase() ? 1 : -1
  );

  const providerSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={providerId || 'all'}
        onChange={handleProviderChange}
        label={intl.formatMessage({ id: "Console.provider"})}
      >
        <option hidden value="" className={css.option}>
          {intl.formatMessage({ id: 'Console.select' })}
        </option>
        <option value="all" className={css.option}>{intl.formatMessage({ id: 'Console.all' })}</option>
        {sortedProviders.map(provider => (
          <option className={css.option} value={provider.id.uuid}>{provider.attributes.name}</option>
        ))}
      </Select>
    </div>
  );

  const handleRatingCheckboxChange = (e, checkedValue) => {
    const newRatings = ratingFilter.map(({ value, checked }) => ({
      value,
      checked: checkedValue === value ? !checked : checked,
    }));

    setRatingFilter(newRatings);

    const rating = newRatings
      .filter(item => item.checked)
      .map(item => item.value)
      .toString();

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        page: 1,
        rating: rating.length ? rating : undefined,
      })}`,
    });
  };

  const ratingCheckboxes = (
    <div className={css.selectWrapper}>
      <p className={css.ratingTitle}>{intl.formatMessage({ id: 'Console.rating' })}</p>
      <div className={css.checkboxContainer}>
        {ratingFilter.map(({ value, checked }) => (
          <Checkbox
            checked={checked}
            onChange={event => handleRatingCheckboxChange(event, value)}
            label={value}
          />
        ))}
      </div>
    </div>
  );

  const handleTimePeriodChange = event => {
    const startDay = event.target.value === 'true' ? firstDay : undefined;
    const endDay = event.target.value === 'true' ? lastDay : undefined;

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        page: 1,
        start: startDay,
        end: endDay,
        periodTime: event.target.value,
      })}`,
    });
  };

  const handleDateRangeSubmit = ({ start, end }) => {
    history.push({ pathname, search: `${stringify({ ...parse(search), page: 1, start, end })}` });
  };

  const dateSelect = (
    <div className={css.dateContainer}>
      <div className={css.selectWrapper}>
        <Select
          value={periodTime || false}
          onChange={handleTimePeriodChange}
          label={intl.formatMessage({ id: "Console.time"})}
        >
          {timePeriodText.map(({ text, value }) => (
            <option value={value}>{intl.formatMessage({ id: text })}</option>
          ))}
        </Select>
      </div>
      {periodTime && (
        <DatePicker onSubmit={handleDateRangeSubmit} value={{ startDate: start, endDate: end }} />
      )}
    </div>
  );

  const showAvgRating = (
    <div className={css.avgRatingContainer}>
      {pagination?.avgRating && !rating && (
        <p className={css.avgRating}>
          {intl.formatMessage(
            { id: 'Console.avgRating' },
            { avgRating: pagination?.avgRating.toFixed(2) }
          )}
        </p>
      )}
    </div>
  );

  const displayTable = reviews?.length ? (
    <Table data={reviews} config={tableConfig} keyFn={keyFn} className={css.table} />
  ) : null;

  const handleExpandClick = event => {
    event.preventDefault();
    setIsExpandedReview(prevState => !prevState);
  };

  const handleExportClick = (event) => {
    event.preventDefault();
    dispatch(exportReviewsToXlsx(search));
  }

  useEffect(() => {
    !rating && setRatingFilter(defaultRatingFilter);
  }, [rating]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <div className={css.filterContainer}>
        {countryReviewSelect}
        {providerSelect}
        {ratingCheckboxes}
        {dateSelect}
        {!!reviews.length && !fetchInProgress ?
          <>
            {showAvgRating}
            <Button className={css.btn} onClick={handleExpandClick}>
              {intl.formatMessage({
                id: isExpandedReview ? 'Console.collapseReview' : 'Console.expandReview',
              })}
            </Button>
            <Button className={css.btn} onClick={handleExportClick} disabled={!periodTime}>
              <FormattedMessage id="Console.export" />
            </Button>
          </> : null}
      </div>
      {fetchInProgress || !currentUser ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <>
          {!!reviews.length && <p className={css.totalItems}>
            {intl.formatMessage({ id: 'Console.numberOfItems' })}:{' '}
            <span>{pagination.totalItems}</span>
          </p>}
          {displayTable}
        </>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleReviewPageComponent.defaultProps = {
  Reviews: [],
  fetchInProgress: false,
  fetchReviewListError: null,
  pagination: null,
  scrollingDisabled: false,
  name: null,
  tab: null,
};

ConsoleReviewPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchReviewListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchReviewListError, pagination, reviewList } = state.ConsoleReviewPage;
  const { currentUser, userProviders } = state.user;

  return {
    fetchInProgress,
    fetchReviewListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    reviews: getMarketplaceEntities(state, reviewList),
    currentUser,
    userProviders,
  };
};

const ConsoleReviewPage = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(ConsoleReviewPageComponent);

ConsoleReviewPage.loadData = loadData;

export default ConsoleReviewPage;
