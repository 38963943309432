import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import moment from 'moment';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { exportTransactionsToXlsx, loadData } from './ConsoleTransactionPage.duck';
import {
  PaginationLinks,
  ConsoleWrapper,
  IconSpinner,
  IconClose,
  Select,
  Input,
} from '../../components';
import TransactionItem from './TransactionItem/TransactionItem';
import CategoryModal from './CategoryModal/CategoryModal';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';

import css from './ConsoleTransactionPage.css';
import DateSelect from './DateSelect/DateSelect';

const transactionStatus = [
  { processStatus: "all", textStatus: "Console.all", serviceFeeState: "Console.all" },
  { processStatus: "payment-expired", textStatus: "InboxPage.stateExpired", serviceFeeState: "InboxPage.stateExpired" },
  { processStatus: "pending-payment", textStatus: "InboxPage.statePending", serviceFeeState: "InboxPage.statePending" },
  { processStatus: "accepted", textStatus: "InboxPage.stateAccepted", serviceFeeState: "DetailOrder.paid" },
  { processStatus: "picked-up", textStatus: "InboxPage.statePickedUp", serviceFeeState: "InboxPage.stateCanceled" },
  { processStatus: "cancelled", textStatus: "InboxPage.stateCanceled", serviceFeeState: "InboxPage.stateCanceled" },
  { processStatus: "delivered", textStatus: "InboxPage.stateDelivered", serviceFeeState: "DetailOrder.paid" },
  { processStatus: "declined", textStatus: "InboxPage.stateDeclined", serviceFeeState: "DetailOrder.paid" },
];

const transactionCountry = [{ countryId: "all", country: "Console.all" }, ...config.countryNames];

const delayedTransactions = [
  { nonDeliveredOnTime: "all", text: "Console.all" },
  { nonDeliveredOnTime: true, text: "ConsoleTransactionPage.yes" },
  { nonDeliveredOnTime: false, text: "ConsoleTransactionPage.no" },
];

const sortDate = [
  { value: "-createdAt", text: "ConsoleTransactionPage.createdDate" },
  { value: "-start,-end,-id", text: "ConsoleTransactionPage.rentDate" },
];

const ConsoleTransactionPageComponent = props => {
  const {
    transactions,
    fetchInProgress,
    scrollingDisabled,
    fetchTransactionListError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    name,
    tab,
    currentUser,
    onManageDisableScrolling,
    exportInProgress
  } = props;
  const { countryId = config.custom.countryId, processState, nonDeliveredOnTime, page, phrase, sort = '-createdAt', businessCategory,  periodTime = false } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numberOfCategories = businessCategory && businessCategory?.trim().split(',').length;
  const isAdmin = currentUser && currentUser.attributes.isAdmin;
  const adminCountries =  currentUser && currentUser.attributes.adminForCountries;
  const defaultAdminCountryId = (isAdmin || (adminCountries && adminCountries.includes(defaultCountryId)))
    ? defaultCountryId
    : adminCountries ? adminCountries[0] : null;
  const dispatch = useDispatch();

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = !fetchInProgress && fetchTransactionListError ? (
    <p className={css.error}>{fetchTransactionListError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !transactions?.length && !fetchTransactionListError ? (
      <p>
        <FormattedMessage id="Console.noTransactionsFound" />
      </p>
    ) : null;

  const handleCountryChange = event => {
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), countryId: searchCountryId, page: 1 })}`,
    });
  };

  const handleDeliveredOnTimeChange = event => {
    const searchNonDeliveredOnTime = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        nonDeliveredOnTime: searchNonDeliveredOnTime,
        page: 1,
      })}`,
    });
  };

  const handleStatusChange = event => {
    const searchProcessState = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), processState: searchProcessState, page: 1 })}`,
    });
  };

  const deliveredOnTimeTransactionSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={typeof nonDeliveredOnTime === 'undefined' ? 'all' : nonDeliveredOnTime}
        onChange={handleDeliveredOnTimeChange}
        label={intl.formatMessage({id: "ConsoleTransactionPage.nonDeliveredOnTime" })}
        isValidation={false}
      >
        <option hidden value="">
          {intl.formatMessage({ id: 'Console.select' })}
        </option>
        {delayedTransactions.map(({ nonDeliveredOnTime, text }) => (
          <option value={nonDeliveredOnTime}>{intl.formatMessage({ id: text })}</option>
        ))}
      </Select>
    </div>
  );

  const countries = currentUser?.attributes.isAdmin
    ? transactionCountry
    : transactionCountry.filter(country =>
        currentUser?.attributes.adminForCountries.includes(country.countryId)
      );

  const countryTransactionSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={countryId}
        onChange={handleCountryChange}
        label={intl.formatMessage({id: "ConsoleTransactionPage.country" })}
        isValidation={false}
      >
        <option hidden value="">
          {intl.formatMessage({ id: 'Console.select' })}
        </option>
        {countries.map(({ countryId, country }) => (
          <option value={countryId}>{intl.formatMessage({ id: country })}</option>
        ))}
      </Select>
    </div>
  );

  const statusTransactionSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={processState || 'all'}
        onChange={handleStatusChange}
        label={intl.formatMessage({id: "Console.transactionStatus" })}
        isValidation={false}
      >
        <option hidden value="">
          {intl.formatMessage({ id: 'Console.select' })}
        </option>
        {transactionStatus.map(({ processStatus, textStatus }) => (
          <option value={processStatus}>{intl.formatMessage({ id: textStatus })}</option>
        ))}
      </Select>
    </div>
  );

  const handleSortDateChange = event => {
    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        sort: event.target.value,
        page: 1,
      })}`,
    });
  };

  const sortDateSelect = (
    <div className={css.selectWrapper}>
      <Select
        value={sort}
        onChange={handleSortDateChange}
        label={intl.formatMessage({id: "ConsoleTransactionPage.sortDate" })}
        isValidation={false}
      >
        {sortDate.map(({ value, text }) => (
          <option value={value}>{intl.formatMessage({ id: text })}</option>
        ))}
      </Select>
    </div>
  );

  const handleBusinessCategoryChange = (subcategories) => {
    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        businessCategory: subcategories,
        page: 1,
      })}`,
    });
  };

  const handleDelete = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), userId: null, page: 1 })}`,
    });
  };

  const userName = parse(search)?.userId ? (
    <button onClick={handleDelete} className={css.closeButton}>
      <span className={css.textButton}>
        {!!transactions?.length ? (
          <>
            {transactions[0]?.user?.attributes?.firstName}{' '}
            {transactions[0]?.user?.attributes?.lastName}
          </>
        ) : (
          parse(search)?.userId
        )}
      </span>
      <IconClose />
    </button>
  ) : null;

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), phrase: searchPhrase, page: 1 })}`,
    });
  };

  const transactionSearch = (
    <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
      <Input
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Console.searchTransaction' })}
        label={intl.formatMessage({id: "Console.search" })}
      />
    </form>
  );

  useEffect(() => {
    if (!page && currentUser) {
      setSearchPhrase('');
      history.push({ pathname, search: `${stringify({ ...parse(search), countryId: defaultAdminCountryId, page: 1 })}` });
    }
  }, [page, currentUser]);

  const handleExportClick = (event) => {
    event.preventDefault();
    dispatch(exportTransactionsToXlsx(search));
  }

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <div className={css.filterContainer}>
        {countryTransactionSelect}
        {statusTransactionSelect}
        {deliveredOnTimeTransactionSelect}
        {sortDateSelect}
        <DateSelect
          history={history}
          pathname={pathname}
          search={search}
          intl={intl}
        />
        <button className={css.btn} onClick={() => setIsModalOpen(true)}>
          <FormattedMessage id="ConsoleTransactionPage.category" />
          {numberOfCategories && <span> ({numberOfCategories})</span>}
        </button>
        <button className={css.btn} onClick={handleExportClick} disabled={!periodTime || exportInProgress}>
          {exportInProgress ? <IconSpinner /> : <FormattedMessage id="Console.export" />}
        </button>
      </div>
      {transactionSearch}
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <>
          {userName}
          {!!transactions.length && <p className={css.totalItems}>
            {intl.formatMessage({ id: 'Console.numberOfItems' })}:{' '}
            <span>{pagination.totalItems}</span>
          </p>}
          <ul className={css.list}>
            {transactions.map(transaction => {
              const {
                id: { uuid },
                attributes: { processState, createdAt, lastTransitionedAt, start, end, lineItems },
                user: {
                  attributes: { firstName, lastName },
                },
                provider: {
                  attributes: { name: providerName, countryId },
                },
                listings,
              } = transaction;

              const createdAtDate = intl.formatDate(createdAt, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });

              const lastTransitionedAtDate = intl.formatDate(lastTransitionedAt, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });

              const currentState = transactionStatus.find(
                state => state.processStatus === processState
              );

              return (
                <TransactionItem
                  key={uuid}
                  id={uuid}
                  state={intl.formatMessage({ id: currentState.textStatus })}
                  createdAt={createdAtDate}
                  lastTransitionedAt={lastTransitionedAtDate}
                  firstName={firstName}
                  lastName={lastName}
                  providerName={providerName}
                  providerCountryId={countryId}
                  listings={listings}
                  startDate={moment(start)}
                  endDate={moment(end).subtract(1, 'days')}
                  intl={intl}
                  processState={processState}
                  lineItems={lineItems}
                />
              );
            })}
          </ul>
        </>
      )}
      <CategoryModal
        isOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        handleSubmit={handleBusinessCategoryChange}
        initBusinessCategory={businessCategory?.trim().split(',')}
        id={"CategoryModal"}
      />
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleTransactionPageComponent.defaultProps = {
  transactions: [],
  fetchInProgress: false,
  fetchTransactionListError: null,
  pagination: null,
  scrollingDisabled: false,
  name: null,
  tab: null,
};

ConsoleTransactionPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchTransactionListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  transactions: arrayOf(propTypes.transactionDetails),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchTransactionListError,
    pagination,
    transactionList,
    exportInProgress
  } = state.ConsoleTransactionPage;
  const { currentUser } = state.user;

  return {
    fetchInProgress,
    fetchTransactionListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionList),
    currentUser,
    exportInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ConsoleTransactionPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleTransactionPageComponent);

ConsoleTransactionPage.loadData = loadData;

export default ConsoleTransactionPage;
